<template>
  <div
    class="vaw-side-bar-wrapper"
    :class="[!state.isCollapse ? 'open-status' : 'close-status']">
    <transition name="logo">
      <Logo v-if="showLogo" />
    </transition>
    <ScrollerMenu>
      <template
        v-for="item of menuList">
        <SideBarItem
          :key="item.path"
          :full-path="item.path"
          :item="item" />
      </template>
    </ScrollerMenu>
    <div
      class="mobile-shadow">
    </div>
  </div>
</template>

<script>
import store from '../store/index'
import { routes } from '@/router/index'
export default {
  name: 'SideBar',
  props: {
    showLogo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      state: store.state,
      menuList: []
    }
  },
  computed: {
    // routes() {
    //   return this.state.permissionRoutes.filter(it => !!it.name)
    // }
  },
  mounted() {
    this.menuList = routes.filter(item => {
      return item.path == '/'
    })[0].children
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/variables.scss';
.open-status {
  width: $menuWidth;
  box-shadow: 2px 5px 5px rgb(202, 202, 202, 0.8);
  transition: all $transitionTime;
}
.close-status {
  width: $minMenuWidth;
  box-shadow: none;
  transition: all $transitionTime;
}
.vaw-side-bar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
  z-index: 999;
  .vaw-menu-wrapper {
    overflow-x: hidden;
    color: white;
  }
  .scrollbar {
    height: calc(100% - #{$logoHeight}) !important;
  }
}
.is-mobile {
  .open-status {
    width: $menuWidth;
    transform: translateX(0);
    transition: transform $transitionTime;
  }
  .close-status {
    width: $menuWidth;
    transform: translateX(-$menuWidth);
    transition: transform $transitionTime;
    box-shadow: none;
  }
}
</style>
<style lang="scss">
@import '../../assets/styles/variables.scss';
.scrollbar-wrap-class {
  overflow-x: hidden !important;
}
.el-menu {
  overflow: hidden !important;
  border-right: none !important;
}
::v-deep {
  .el-menu--collapse .el-submenu__title span {
    display: none !important;
  }
  .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    display: none !important;
  }
}
</style>
