/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-10-18 14:05:31
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 14:04:28
 * @FilePath: /siana_admin/src/utils/index.js
 */
import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import './router'

import * as eCharts from 'echarts'

import permission from '@/directive/permission'

Vue.use(Element)
Vue.use(permission)

Vue.prototype.$echarts = eCharts
Vue.prototype.$isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
Vue.prototype.$isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1

Vue.prototype.$successMsg = function (message = '') {
  this.$message({
    message,
    type: 'success',
    duration: 5000
  })
}
Vue.prototype.$errorMsg = function (message = '') {
  this.$message({
    message,
    type: 'error',
    duration: 5000
  })
}
Vue.prototype.$showConfirmDialog = function (message = '') {
  return this.$confirm(message, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
}

Vue.prototype.publishEvent = function (eventName, payload) {
  if (!eventName) return
  const fun = this[eventName]
  fun && fun(payload)
}

Vue.prototype.registeEvent = function (events) {
  for (const item in events) {
    const handle = events[item]
    if (handle instanceof Function) {
      this[item] = handle.bind(this)
    }
  }
}

Vue.prototype.isInited = function (prop) {
  return this[prop] && this[prop].init
}
