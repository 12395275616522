/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-10-18 14:05:31
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-29 17:12:11
 * @FilePath: /siana_admin/src/utils/router.js
 */
import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import LayoutStore, { Layout } from '@/layouts/index'

NProgress.configure({ showSpinner: false })

function isTokenExpired() {
  const token = window.localStorage.getItem('token')
  return !!token
}

const whiteList = ['login', 'white']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (whiteList.includes(to.name)) {
    next()
    document.title = '管理系统-' + to.meta.title
    NProgress.done()
  } else {
    if (!isTokenExpired()) {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    } else {
      const isEmptyRoute = LayoutStore.isEmptyPermissionRoute()
      if (isEmptyRoute) {
        document.title = '管理系统-' + to.meta.title
        next()
      }
    }
  }
})

router.afterEach((to, from) => {
  NProgress.done()
})
