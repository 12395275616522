/*
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-10-18 14:05:31
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 14:03:25
 * @FilePath: /siana_admin/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { REQUEST } from '@/api_new/http'
import JsonExcel from 'vue-json-excel'
import './icons'
import './utils'
import '@/styles/index.scss'
Vue.prototype.$https = REQUEST.POST
Vue.prototype.$https.get = REQUEST.GET
import LayoutStore from '@/layouts/index'
import { resetRouter } from './router/index'
import Modal from '@/components/modal/modal.vue'
import JsonViewer from 'vue-json-viewer'

Vue.component('downloadExcel', JsonExcel)
Vue.use(JsonViewer)

Vue.use(LayoutStore, {
  state: {
    isFixedNavBar: true,
    layoutMode: 'ltr',
    themeColor: 'theme_color_blue',
    theme: 'dark-side'
  },
  actions: {
    onLogout() {
      resetRouter()
      router.replace({ name: 'login' })
    },
    toPersonalCenter() {
      router.push('/personal/center')
    }
  }
})


// 滚动加载更多
Vue.directive('loadMore', {
    bind(el, binding) {
        // 获取element，定义scroll
        let select_dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        select_dom.addEventListener('scroll', function () {
        let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
        if (height) {
            binding.value()
        }
        })
    }
});


Vue.config.productionTip = false
Vue.component('b-modal', Modal)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
