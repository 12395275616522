/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-10-18 14:05:31
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 13:50:09
 * @FilePath: /siana_admin/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { Layout } from '@/layouts/index'
import RouterViewLayout from '@/layouts/RouterViewLayout.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export const routes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/white',
    name: 'white',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/white/white.vue'),
    hidden: true
  },
  {
    path: '/',
    name: 'index',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '',
        name: 'index',
        component: () => import('@/views/index/work-place'),
        meta: {
          title: '首页',
          icon: 'el-icon-s-home',
          cacheable: true
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/user/index'),
        meta: {
          icon: 'el-icon-s-custom',
          title: '用户管理',
          cacheable: false
        }
      },
    //   {
    //     path: 'product',
    //     name: 'product',
    //     component: () => import('@/views/product/index'),
    //     meta: {
    //       icon: 'el-icon-suitcase',
    //       title: '商品管理',
    //       cacheable: false
    //     }
    //   },
      {
        path: 'product2',
        name: 'product2',
        component: () => import('@/views/product2/Product2Manager.vue'),
        meta: {
          icon: 'el-icon-suitcase',
          title: '商品管理(新)',
          cacheable: false
        }
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/order/index'),
        meta: {
          icon: 'el-icon-document-remove',
          title: '订单管理',
          cacheable: false
        }
      },
      {
        path: 'gptkey',
        name: 'gptkey',
        component: () => import('@/views/gpt_key/index'),
        meta: {
          icon: 'el-icon-thumb',
          title: 'GPTKEY',
          cacheable: false
        }
      },
      {
        path: 'cardpin',
        name: 'cardpin',
        component: () => import('@/views/card_pin/index'),
        meta: {
          icon: 'el-icon-postcard',
          title: '卡密管理',
          cacheable: false
        }
      },
      {
        path: 'mail',
        name: 'mail',
        component: () => import('@/views/email/index'),
        meta: {
          icon: 'el-icon-message',
          title: '邮箱管理',
          cacheable: false
        }
      },
      {
        path: 'optimizeList',
        name: 'optimizeList',
        component: () => import('@/views/question/optimizeList.vue'),
        meta: {
          icon: 'el-icon-message',
          title: '优化问题记录',
          cacheable: false
        }
      },
      {
        path: 'invite',
        name: 'invite',
        meta: {
          icon: 'el-icon-s-tools',
          title: '邀请赚钱',
          cacheable: false
        },
        component: RouterViewLayout,
        children:[
          {
            path: '/invite/moneyConfig',
            name: 'moneyConfig',
            component: () => import('@/views/invite/moneyConfig.vue'),
            meta: {
              icon: 'el-icon-picture-outline-round',
              title: '配置提现金额选项',
              cacheable: false
            }
          },
          {
            path: '/invite/commissionConfig',
            name: 'commissionConfig',
            component: () => import('@/views/invite/commissionConfig.vue'),
            meta: {
              icon: 'el-icon-picture-outline-round',
              title: '分成配置',
              cacheable: false
            }
          },
          {
            path: '/invite/WithdrawManager',
            name: 'WithdrawManager',
            component: () => import('@/views/invite/WithdrawManager.vue'),
            meta: {
              icon: 'el-icon-picture-outline-round',
              title: ' 提现管理',
              cacheable: false
            }
          }
        ]
      },
      {
        path: 'system',
        name: 'system',
        meta: {
          icon: 'el-icon-s-tools',
          title: '系统配置',
          cacheable: false
        },
        component: RouterViewLayout,
        children: [
          {
            path: '/system/index',
            name: 'platform',
            component: () => import('@/views/system/platform/index'),
            meta: {
              icon: 'el-icon-box',
              title: '平台设置',
              cacheable: false
            }
          },
           {
            path: '/system/stablediffusion',
            name: 'stablediffusion',
            component: () => import('@/views/system/stablediffusion/index'),
            meta: {
              icon: 'el-icon-picture-outline-round',
              title: 'StableDiffusion配置',
              cacheable: false
            }
          }, {
            path: '/system/midjourney',
            name: 'midjourney',
            component: () => import('@/views/system/midjourney/index'),
            meta: {
              icon: 'el-icon-postcard',
              title: 'Midjourney配置',
              cacheable: false
            }
          }, {
            path: '/system/flagstudio',
            name: 'flagstudio',
            component: () => import('@/views/system/flagstudio/index'),
            meta: {
              icon: 'el-icon-tickets',
              title: 'FlagStudio配置',
              cacheable: false
            }
          }, {
            path: '/system/baidu',
            name: 'baidu',
            component: () => import('@/views/system/baidu/index'),
            meta: {
              icon: 'el-icon-set-up',
              title: '百度配置',
              cacheable: false
            }
          }, {
            path: '/system/agent',
            name: 'agent',
            component: () => import('@/views/system/agent/index'),
            meta: {
              icon: 'el-icon-connection',
              title: '代理配置',
              cacheable: false
            }
          },
          {
            path: '/system/bing',
            name: 'bing',
            component: () => import('@/views/system/bing/index'),
            meta: {
              icon: 'el-icon-discount',
              title: 'Bing配置',
              cacheable: false
            }
          },
          {
            path: '/system/pay',
            name: 'pay',
            component: () => import('@/views/system/pay/index'),
            meta: {
              icon: 'el-icon-money',
              title: '支付配置',
              cacheable: false
            }
          }

        ]
      },
      {
        path: 'log',
        name: 'log',
        component: () => import('@/views/log/index'),
        meta: {
          icon: 'el-icon-document',
          title: '错误日志',
          cacheable: false
        }
      },
        {
            path: 'moneyPayManager',
            name: 'moneyPayManager',
            component: () => import('@/views/knowledge/moneyPayManager'),
            meta: {
            icon: 'el-icon-document',
            title: '充值管理',
            cacheable: false
            }
        },
        {
            path: 'monthlyPayManager',
            name: 'monthlyPayManager',
            component: () => import('@/views/knowledge/monthlyPayManager'),
            meta: {
            icon: 'el-icon-document',
            title: '包月管理',
            cacheable: false
            }
        },
        // {
        //     path: 'coupons',
        //     name: 'coupons',
        //     component: () => import('@/views/coupons/index'),
        //     meta: {
        //       icon: 'el-icon-document',
        //       title: '优惠劵管理',
        //       cacheable: false
        //     }
        // },
        {
            path: 'appQuestionRecordManager',
            name: 'appQuestionRecordManager',
            component: () => import('@/views/appQuestionRecordManager/appQuestionRecordManager.vue'),
            meta: {
                icon: 'el-icon-document',
                title: '提问记录',
                cacheable: false
            }
        },
        {
            path: 'OrgPruductManager',
            name: 'OrgPruductManager',
            component: () => import('@/views/orgPruduct/OrgPruductManager'),
            meta: {
                icon: 'el-icon-document',
                title: '组织套餐',
                cacheable: false
            }
        },
        {
            path: 'OrgPruductManager2',
            name: 'OrgPruductManager2',
            component: () => import('@/views/orgPruduct2/OrgPruductManager2'),
            meta: {
                icon: 'el-icon-document',
                title: '星舰会员套餐默认设置',
                cacheable: false
            }
        },
        {
            path: 'OrgIncrementalManager',
            name: 'OrgIncrementalManager',
            component: () => import('@/views/incremental/OrgIncrementalManager'),
            meta: {
                icon: 'el-icon-document',
                title: '增量包商品管理',
                cacheable: false
            }
        },
        {
          path: 'app',
          name: 'app',
          meta: {
            icon: 'el-icon-s-tools',
            title: '应用',
            cacheable: false
          },
          component: RouterViewLayout,
          children:[
            {
              path: '/app/appManager',
              name: 'appManager',
              component: () => import('@/views/app/appManager'),
              meta: {
                icon: 'el-icon-thumb',
                title: '应用管理',
                cacheable: false
              }
            }
          ]
        },
        {
            path: 'ModelManager',
            name: 'ModelManager',
            component: () => import('@/views/modelManager/ModelManager.vue'),
            meta: {
                icon: 'el-icon-document',
                title: '模型管理',
                cacheable: false
            }
        },
        {
            path: 'OrgManager',
            name: 'OrgManager',
            component: () => import('@/views/org/OrgManager.vue'),
            meta: {
                icon: 'el-icon-document',
                title: '组织管理',
                cacheable: false
            }
        },
        {
            path: 'WeChatGroupManager',
            name: 'WeChatGroupManager',
            component: () => import('@/views/weChatGroup/WeChatGroupManager.vue'),
            meta: {
                icon: 'el-icon-document',
                title: '微信群管理',
                cacheable: false
            }
        }

        
        
    ]
  }
]

const createRouter = () => new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
