<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-10-18 14:05:31
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-17 09:22:02
 * @FilePath: /admin-main/src/App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    if (this._isMobile()) {
      window.localStorage.setItem('phone', true)
    } else {
      window.localStorage.setItem('phone', false)
    }
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  }
}
</script>
<style>
@import url('../src/assets/styles/style.scss');
</style>
